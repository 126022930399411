import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Guides & Tools",
  "sort": 1,
  "title": "Private key JWT authentication",
  "subtitle": "How to use private key JWT to authenticate your Criipto application"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Private Key JWT Authentication is one of the client authentication methods defined in `}<a parentName="p" {...{
        "href": "https://openid.net/specs/openid-connect-core-1_0.html#ClientAuthentication"
      }}>{`Open ID Connect Core Client Authentication`}</a>{`.
It relies on using JWT assertions signed with asymmetric key pairs for client authentication.`}</p>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`Private Key JWT Authentication (`}<inlineCode parentName="p">{`private_key_jwt`}</inlineCode>{`) offers a `}<a parentName="p" {...{
          "href": "/verify/guides/privatekey-jwt/#advantages-of-private-key-jwt-authentication"
        }}>{`more secure alternative`}</a>{` to symmetric credentials (`}<inlineCode parentName="p">{`client_secret`}</inlineCode>{` authentication options)
for client authentication in `}<a parentName="p" {...{
          "href": "/verify/getting-started/oidc-intro/#using-openid-connect-to-integrate-with-criipto-verify"
        }}>{`traditional server-based web applications`}</a>{`.`}</p>
    </Highlight>
    <p>{`You can configure your `}<a parentName="p" {...{
        "href": "https://docs.criipto.com/verify/getting-started/basics/#applications"
      }}>{`Criipto Verify Application`}</a>{` to use `}<inlineCode parentName="p">{`private_key_jwt`}</inlineCode>{` for client authentication.`}</p>
    <h2>{`Authenticate with Private Key JWT`}</h2>
    <p>{`To use `}<inlineCode parentName="p">{`private_key_jwt`}</inlineCode>{` for client authentication, follow these three steps:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Generate an RSA key pair and a JSON Web Key Set(JWKS).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Build the client assertion.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Use the client assertion to authenticate your application against Criipto Verify.`}</p>
      </li>
    </ol>
    <p>{`You can use one of the recommended libraries for working with JWTs (the list is available at `}<a parentName="p" {...{
        "href": "https://jwt.io/libraries"
      }}>{`jwt.io`}</a>{`), or leverage Criipto's `}<a parentName="p" {...{
        "href": "https://github.com/criipto/criipto-oidc-js"
      }}>{`criipto-oidc-js`}</a>{` library. We strongly recommend utilizing an established tooling or a third-party library rather than building your own solution.`}</p>
    <h3>{`Generate an RSA key pair and JWKS`}</h3>
    <p>{`Generate an RSA key pair and a JWKS pointing to the public key. You'll need to `}<a parentName="p" {...{
        "href": "/verify/guides/privatekey-jwt/#configure-private-key-jwt-authentication"
      }}>{`add the JWKS to your Application settings`}</a>{` from the Dashboard later on.`}</p>
    <Highlight icon="exclamation" mdxType="Highlight">
      <p>{`Ideally, you would manage and expose your keys via a JWKS endpoint, but for the purposes of this guide, we will work with a static JWKSet.`}</p>
    </Highlight>
    <p>{`The code sample below uses `}<a parentName="p" {...{
        "href": "https://github.com/panva/jose"
      }}>{`jose library`}</a>{` to generate a key pair and create a JWKS.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import * as jose from 'jose';

async function generateKeys() {
  try {
    // Generate RSA key pair (public and private keys)
    const { publicKey, privateKey } = await jose.generateKeyPair('RS256');
    // Export the public key to JWK format
    const jwk = await jose.exportJWK(publicKey);
    const jwks = { keys: [jwk] };
    // Log the JWKS (public key in JWK Set format)
    console.log('JWK Set (Public Key):', JSON.stringify(jwks, null, 2));
    // Your private key here. Ensure to store your private key in a secure environment.
    console.log('Private Key:', privateKey);
    return { publicKey, privateKey, jwks };
  } catch (error) {
    console.error(error);
    throw error;
  }
}
`}</code></pre>
    <h3>{`Build the client assertion`}</h3>
    <p>{`The client assertion is a JWT signed by the private key of the `}<a parentName="p" {...{
        "href": "/verify/guides/privatekey-jwt/#generate-an-rsa-key-pair-and-jwks"
      }}>{`RSA key pair`}</a>{` generated earlier. It should contain the following claims:`}</p>
    <p><inlineCode parentName="p">{`alg`}</inlineCode>{`: The algorithm used to sign the JWT. Should be set to `}<inlineCode parentName="p">{`RS256`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`iss`}</inlineCode>{`: The Client ID of your Criipto Verify application. You can find it in the General tab of your Application settings under `}<inlineCode parentName="p">{`Client ID/Realm`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`sub`}</inlineCode>{`: Your application's Client ID (the same as in the `}<inlineCode parentName="p">{`iss`}</inlineCode>{` claim).`}</p>
    <p><inlineCode parentName="p">{`aud`}</inlineCode>{`: Your Criipto Verify domain that will receive the assertion, e.g. `}<inlineCode parentName="p">{`https://{{YOUR_CRIIPTO_DOMAIN}}.criipto.id`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`iat`}</inlineCode>{` and `}<inlineCode parentName="p">{`exp`}</inlineCode>{`: Issued At and Expiration claims set to the correct timestamps. The client assertion JWT should be used only once and should have a short lifespan. We recommend a maximum expiry time of 5 minutes.`}</p>
    <h4>{`Example`}</h4>
    <p>{`In the code sample below, the assertion is generated using the `}<a parentName="p" {...{
        "href": "https://github.com/panva/jose"
      }}>{`jose library`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const { SignJWT } = require('jose');

const signingKey = 'YOUR_SIGNING_KEY_HERE';

async function generateJWT(signingKey) {
  try {
    const jwt = await new SignJWT({})
      .setProtectedHeader({ alg: 'RS256' })
      .setIssuedAt()
      .setIssuer('CLIENT_ID')
      .setSubject('CLIENT_ID')
      .setAudience('YOUR_CRIIPTO_DOMAIN')
      .setExpirationTime('5m')
      .sign(signingKey);
    console.log('JWT:', jwt);
    return jwt;
  } catch (error) {
    console.error('Error generating JWT:', error.message);
    throw error;
  }
}
`}</code></pre>
    <h3>{`Exchange the client assertion for an access token`}</h3>
    <p>{`You'll need to include the client assertion along with the verification code when performing the `}<a parentName="p" {...{
        "href": "/verify/getting-started/oidc-intro/#exchange-the-code-for-a-token"
      }}>{`code-for-token exchange`}</a>{`.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`client_assertion_type`}</inlineCode>{` must be set to `}<inlineCode parentName="p">{`urn:ietf:params:oauth:client-assertion-type:jwt-bearer`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`# Replace \`$client_assertion\` with your JWT assertion
# Replace \`AUTHORIZATION_CODE\` with the authorization code received in the initial authorization request
# Replace \`YOUR_DOMAIN.criipto.id\` with your Criipto domain
# Replace \`YOUR_RETURN_URL\` with the redirect URI used in the initial authorization request

HTTP POST https://YOUR_DOMAIN.criipto.id/oauth2/token
Content-Type: application/x-www-form-urlencoded

grant_type=authorization_code
&client_assertion_type=urn:ietf:params:oauth:client-assertion-type:jwt-bearer
&client_assertion=$client_assertion
&code=AUTHORIZATION_CODE
&redirect_uri=YOUR_RETURN_URL
`}</code></pre>
    <h2>{`Configure Private Key JWT Authentication`}</h2>
    <p>{`To configure Private Key JWT Authentication, you'll need to register JWKS on behalf of your client on the `}<a parentName="p" {...{
        "href": "https://dashboard.criipto.com"
      }}>{`management dashboard`}</a>{`.`}</p>
    <p>{`In your Application settings, go to `}<inlineCode parentName="p">{`OpenID Connect`}</inlineCode>{` > `}<inlineCode parentName="p">{`Client JWKS`}</inlineCode>{`. You can add a static JWKS or a JWKS endpoint.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "29.6875%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA9UlEQVQY052RPUoEQRBG50BmpoZ6BBNDI72JgYmgRzAUA1kDEUQjRVxjE0FBVnac6Z/p7unp3u4ns4O6LBhowaM+iqoPqqpQWjKZlrxPS0Jn53Te/JsihBbZOIS21MoQYiTnREo/5KW8TN+fcyYE3xt6jHFI1dAYR0qZlDN/id6sn4ixo/BO463AW0n0mlnXMOsMWglE/YGUNVoPWqkaKSqEGOoDFf2WMXpC5yienl85ubjj7OqB85tHTi/vGV2PqaqS1kqskbh5FnM9sKglvm2+KY5Ht6xs7rG2fcDG7hGrW/us7xzy8jaB1OLbr4MvPuv3x30CjSrGC1H8bskAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Client JWKS Endpoint",
          "title": "Client JWKS Endpoint",
          "src": "/static/cd1b465baaa5b14428df6513002a0438/e5715/JWKS_endpoint.png",
          "srcSet": ["/static/cd1b465baaa5b14428df6513002a0438/8514f/JWKS_endpoint.png 192w", "/static/cd1b465baaa5b14428df6513002a0438/804b2/JWKS_endpoint.png 384w", "/static/cd1b465baaa5b14428df6513002a0438/e5715/JWKS_endpoint.png 768w", "/static/cd1b465baaa5b14428df6513002a0438/4ad3a/JWKS_endpoint.png 1152w", "/static/cd1b465baaa5b14428df6513002a0438/236d7/JWKS_endpoint.png 1486w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Static: Add your static JWKS.`}</li>
      <li parentName="ul">{`JWKS Endpoint: Provide JWKS URL to fetch JWKS dynamically (recommended).`}</li>
    </ul>
    <h2>{`Advantages of Private Key JWT Authentication`}</h2>
    <p><inlineCode parentName="p">{`private_key_jwt`}</inlineCode>{` offers enhanced security compared to traditional `}<inlineCode parentName="p">{`client_secret`}</inlineCode>{` authentication methods by removing the need for a pre-shared symmetric key (client secret).`}</p>
    <p>{`Instead, you generate a pair of asymmetric keys and have Criipto point to the JWKS in your system. During authentication, your application signs its request with the private key, creating a signed client assertion. Criipto Verify can then use the public key from the JWKS to decrypt and verify the assertion.`}</p>
    <p>{`Additionally, by using `}<inlineCode parentName="p">{`private_key_jwt`}</inlineCode>{` with a `}<em parentName="p">{`JWKS endpoint`}</em>{` rather than a static JWKS, you can benefit from seamless key rollover by eliminating the need to make any changes in the Criipto Dashboard if you choose to rotate your keys.`}</p>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`With `}<inlineCode parentName="p">{`client_secret`}</inlineCode>{`:`}</p>
      <p>{`Criipto Verify generates the `}<inlineCode parentName="p">{`client_secret`}</inlineCode>{`. Every time the application wants to perform client authentication, it must send the `}<inlineCode parentName="p">{`client_secret`}</inlineCode>{` in order to obtain an access token.`}</p>
      <p>{`With `}<inlineCode parentName="p">{`private_key_jwt`}</inlineCode>{`:`}</p>
      <p>{`You generate your own asymmetric key pair and register it with Criipto Verify by supplying your JWKS. Therefore, the private key is under your control and is never shared or transmitted over the network.`}</p>
    </Highlight>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      